<style lang="less" scoped>
.fearless-center-details {
  width: 100%;
  background: #070911;
  color: rgba(222, 230, 255, 1);
  &-first {
    width: 100%;
    &-container {
      width: 76.7%;
      max-width: 1104px;
      margin: 0 auto;
      padding: 196px 0;
      height: 400px;
      display: flex;
      justify-content: space-between;
      &-left {
        width: 49.8%;
        img {
          width: 100%;
        }
      }
      &-right {
        width: 43.1%;
        p {
          margin: 0;
          font-family: "PingFangSC-Regular", "PingFang SC";
          color: rgba(222, 230, 255, 1);
          font-weight: 400;
          &:nth-child(1) {
            font-size: 30px;
            line-height: 42px;
            letter-spacing: 4px;
          }
          &:nth-child(2) {
            font-size: 10px;
            line-height: 14px;
            letter-spacing: 4px;
            margin: 5px 0 28px;
          }
          &:nth-child(3) {
            font-size: 16px;
            line-height: 40px;
            letter-spacing: 2px;
          }
        }
      }
    }
  }
  &-second {
    width: 100%;
    &-container {
      width: 76.7%;
      max-width: 1104px;
      margin: 0 auto;
      font-size: 16px;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 2px;
    }
  }
  &-third {
    width: 100%;
    height: 698px;
    background: #0f1118;
    &-container {
      width: 76.7%;
      height: 100%;
      max-width: 1104px;
      margin: 0 auto;
      margin-top: 101px;
      overflow: hidden;
      position: relative;
      img {
        width: 88%;
        margin: 6%;
      }
    }
  }
  /deep/
    .fearless-center-details-third-container.swiper-container-initialized.swiper-container-horizontal {
    .swiper-button-next,
    .swiper-button-prev {
      width: 51px;
      height: 51px;
      background: rgba(222, 230, 255, 1);
      border-radius: 50%;
      color: #0f1118;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
      &:after {
        font-size: 14px;
        font-weight: bolder;
      }
    }
    .swiper-button-next {
      right: 0;
    }
    .swiper-button-prev {
      left: 0;
    }
  }
}
</style>

<template>
  <div class="fearless-center-details">
    <div class="fearless-center-details-first">
      <div class="fearless-center-details-first-container">
        <div class="fearless-center-details-first-container-left">
          <img src="../assets/fearless/fearless_2.png"
               alt="">
        </div>
        <div class="fearless-center-details-first-container-right">
          <p>
            我们是谁
          </p>
          <p>
            ABOUT Wuwei
          </p>
          <p>
            <!-- 上海无畏创意经济区域合作促进中心主要发起单位为长三角创意经济合作专业委员会，经上海市人民政府合作交流办公室批准，在上海市民政局正式注册成立的非营利性社会服务组织，作为长三角创意经济合作专业委员会对外开展工作的执行机构。由十一届全国政协副主席厉无畏担任促进中心名誉理事长。 -->
            上海无畏创意经济区域合作促进中心，由十一届全国政协副主席、“中国创意经济之父”厉无畏教授积极推进，长三角创意经济合作专业委员会牵头发起，经上海市人民政府合作交流办公室批准，在上海市民政局正式注册成立的非营利性社会服务组织，作为长三角创意经济合作专业委员会对外开展工作的执行机构。
          </p>
          <img src="../assets/weibo.png"
               alt=""
               style="width: 30px; marginTop: 33px; cursor: pointer"
               @click="linkTo('https://weibo.com/u/6096290535', '_blank')">
          <img src="../assets/weixin.png"
               alt=""
               style="width: 30px; marginLeft: 30px; cursor: pointer"
               @click="linkTo('https://mp.weixin.qq.com/s/Xpwm4f_M0hJEGKGVZsf_6Q', '_blank')">
        </div>
      </div>
    </div>
    <div class="fearless-center-details-second">
      <p class="fearless-center-details-second-container">
        长三角创意经济合作专业委员会 (creative economy cooperation committee,简称CECC)是由长江三角洲城市经济协调会第十六届市长联席会议批准设立的跨部门、跨地区和跨行业的区域性专业类政府间合作机构，是协调会集聚文化创意经济资源和对外开展创意经济交流活动的主要载体。根据市长联席会议要求，专委员会在上海市文化创意产业推进领导小组办公室、上海市经济和信息化委员会、上海市对外合作交流办公室的指导下，在长三角协调会办公室的直接领导下开展工作，为长三角协调会41个会员城市推进创意经济发展提供专业化服务。CECC也是世界创意经济区域合作国际组织成员单位。
      </p>
      <p class="fearless-center-details-second-container"
         :style="{marginTop: '32px'}">
        CECC以世界创意经济峰会(简称SWCES)为交流合作平台，聚焦于创意大设计、创意新商业和创意酷园区三大业务版块，利用互联网技术创意性塑造新商业场景，并由园区进行功能承载，从而构建数字设计驱动的创意设计服务价值链。在业务推进中，将价值创新观和人本发展观相结合，促进中心不仅要成为城市经济发展决策智库，还要孵化创意科技企业、打造创意空间名园，并在促进创意产品设计开发和推广服务等方面开展项目交流对接活动，以国际视野推进创意经济的区域合作。
      </p>
    </div>
    <div class="fearless-center-details-third">
      <div class="fearless-center-details-third-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/fearless/fearless_sw_1.png"
                 alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/fearless/fearless_sw_5.png"
                 alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/fearless/fearless_sw_4.png"
                 alt="">
          </div>
          <div class="swiper-slide">
            <img src="../assets/fearless/fearless_s_1.png"
                 alt="">
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    <Common-activies :style="{maxWidth: '1104px', margin: '0 auto'}"></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import CommonActivies from '../components/common-activities-c';
import CommonFooter from '../components/common-footer';
export default {
  name: 'aboutUs-details',
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
    new Swiper('.fearless-center-details-third-container', {
      autoplay: {
        disableOnInteraction: false,
        delay: 2000
      },
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    }
  },
  components: {
    CommonFooter,
    CommonActivies
  },
}
</script>
